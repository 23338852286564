import React from 'react';
import {Link} from 'gatsby';

const IconList = () => (
  <div
    className="ui horizontal list"
    style={{
      paddingBottom: '2rem',
    }}
  >
    <a
      target="_blank"
      rel="noreferrer"
      href="https://twitter.com/noynote"
      className="item"
    >
      <i className="big twitter icon" />
    </a>
    <Link target="_blank" to="rss.xml" className="item">
      <i className="big rss icon" />
    </Link>
  </div>
);

export default IconList;
