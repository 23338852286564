import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../templates/layout';
import ArticleList from '../components/ArticleList';
import IconList from '../components/IconList';
import SEO from '../components/SEO';
import {AllMarkdownInformationQuery} from '../types/graphql-types';

export default function Index({
  data: {allMarkdownRemark, site},
}: {
  data: AllMarkdownInformationQuery;
}) {
  return (
    <Layout>
      <SEO
        title={site?.siteMetadata?.title || 'empty'}
        description={site?.siteMetadata?.description || 'empty'}
        image={site?.siteMetadata?.image || 'empty'}
        isArticle={false}
        url={site?.siteMetadata?.siteUrl || 'empty'}
      />
      <IconList />
      <ArticleList nodes={allMarkdownRemark.nodes} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query AllMarkdownInformation {
    site {
      siteMetadata {
        title
        description
        image
        siteUrl
      }
    }
    allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}) {
      nodes {
        fields {
          slug
          description
          thumbnail
        }
        frontmatter {
          date(formatString: "YYYY-MM-DD")
          title
          tags
        }
      }
    }
  }
`;
